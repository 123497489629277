import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/navigate_right_black_24px.png'
import _imports_1 from '@/assets/icons/white_arrow_right_24px.png'
import _imports_2 from '@/assets/icons/white_publish_24px.png'
import _imports_3 from '@/assets/image/logo_myculture.png'
import _imports_4 from '@/assets/icons/navigate_left_black_24px.png'
import _imports_5 from '@/assets/icons/white_navigate_back_24px.png'


const _hoisted_1 = {
  key: 0,
  class: "body-width modal"
}
const _hoisted_2 = { class: "box-card" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "modal-footer" }
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_8 = {
  key: 2,
  class: "btn btn-create btn-progress btn-primary disable-true"
}
const _hoisted_9 = {
  key: 1,
  class: "menu-container flex-center"
}
const _hoisted_10 = { class: "body-width-reponsive-max" }
const _hoisted_11 = {
  class: "nav-bar-container flex-center",
  style: {"flex-direction":"row"}
}
const _hoisted_12 = {
  class: "flex-center",
  style: {"width":"30%"}
}
const _hoisted_13 = { class: "label-container" }
const _hoisted_14 = { class: "nav-text-header" }
const _hoisted_15 = { style: {"width":"70%","height":"50px","flex-basis":"100%","display":"flex","align-items":"center","place-content":"flex-end"} }
const _hoisted_16 = { class: "fs-12 fw-700" }
const _hoisted_17 = { class: "fs-12 fw-700" }
const _hoisted_18 = {
  key: 0,
  src: _imports_0,
  class: "icon-20 ml-12",
  alt: "navigate_right_black_24px"
}
const _hoisted_19 = {
  key: 1,
  src: _imports_1,
  class: "icon-20 ml-12",
  alt: "icons_white_arrow_right_24px"
}
const _hoisted_20 = {
  key: 1,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_21 = { class: "fs-12 fw-700" }
const _hoisted_22 = { class: "ml-24 flex-mid" }
const _hoisted_23 = {
  key: 3,
  class: "ml-16 btn02 btn-40 disable-true"
}
const _hoisted_24 = { class: "fs-12 fw-700 mr-16" }
const _hoisted_25 = {
  key: 2,
  class: "body-width",
  id: "assessment_setup_values_and_traits"
}
const _hoisted_26 = { style: {"margin-top":"6.4rem"} }
const _hoisted_27 = {
  class: "body-container-with-step",
  style: {"margin-top":"6.4rem"}
}
const _hoisted_28 = { class: "step-menu-container vertical" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "menu-inner" }
const _hoisted_31 = { class: "step-num" }
const _hoisted_32 = { class: "step-name" }
const _hoisted_33 = {
  key: 0,
  class: "step-desc"
}
const _hoisted_34 = { class: "" }
const _hoisted_35 = {
  key: 0,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_36 = {
  key: 0,
  class: "width-max-520"
}
const _hoisted_37 = { class: "card-title" }
const _hoisted_38 = { class: "fs-16 mt-12" }
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_41 = ["onUpdate:modelValue", "onInput"]
const _hoisted_42 = { class: "text-container" }
const _hoisted_43 = { class: "text" }
const _hoisted_44 = {
  key: 1,
  class: "x-card-question-padding x-box-card",
  style: {"min-height":"420px"}
}
const _hoisted_45 = { class: "input-group-container" }
const _hoisted_46 = { class: "fs-14 opa-08" }
const _hoisted_47 = { class: "input-inner" }
const _hoisted_48 = { class: "input-title" }
const _hoisted_49 = {
  width: "8",
  height: "8",
  viewBox: "0 0 8 8",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_50 = ["fill"]
const _hoisted_51 = { class: "input-desc" }
const _hoisted_52 = { class: "input-container-wrapper" }
const _hoisted_53 = { class: "input-container-02" }
const _hoisted_54 = ["onUpdate:modelValue", "onInput"]
const _hoisted_55 = {
  for: "",
  class: "label"
}
const _hoisted_56 = ["onClick"]
const _hoisted_57 = ["onClick"]
const _hoisted_58 = {
  key: 2,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_59 = { class: "width-max-520" }
const _hoisted_60 = { class: "card-title" }
const _hoisted_61 = { class: "fs-16 mt-12" }
const _hoisted_62 = ["innerHTML"]
const _hoisted_63 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_64 = ["onUpdate:modelValue", "onInput"]
const _hoisted_65 = { class: "text-container" }
const _hoisted_66 = { class: "text" }
const _hoisted_67 = {
  key: 3,
  class: "x-card-question-padding x-box-card",
  style: {"min-height":"420px"}
}
const _hoisted_68 = { class: "input-group-container" }
const _hoisted_69 = { class: "fs-14 opa-08" }
const _hoisted_70 = { class: "input-inner" }
const _hoisted_71 = { class: "input-title" }
const _hoisted_72 = {
  width: "8",
  height: "8",
  viewBox: "0 0 8 8",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_73 = ["fill"]
const _hoisted_74 = { class: "input-desc" }
const _hoisted_75 = { class: "input-container-wrapper" }
const _hoisted_76 = { class: "input-container-02" }
const _hoisted_77 = ["onUpdate:modelValue", "onInput"]
const _hoisted_78 = {
  for: "",
  class: "label"
}
const _hoisted_79 = ["onClick"]
const _hoisted_80 = ["onClick"]
const _hoisted_81 = {
  key: 4,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_82 = { class: "width-max-520" }
const _hoisted_83 = { class: "card-title" }
const _hoisted_84 = { class: "fs-16 mt-12" }
const _hoisted_85 = ["innerHTML"]
const _hoisted_86 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_87 = ["onUpdate:modelValue", "onInput"]
const _hoisted_88 = { class: "text-container" }
const _hoisted_89 = { class: "text" }
const _hoisted_90 = {
  key: 5,
  class: "x-card-question-padding x-box-card",
  style: {"min-height":"420px"}
}
const _hoisted_91 = { class: "input-group-container" }
const _hoisted_92 = { class: "fs-14 opa-08" }
const _hoisted_93 = { class: "input-inner" }
const _hoisted_94 = { class: "input-title" }
const _hoisted_95 = {
  width: "8",
  height: "8",
  viewBox: "0 0 8 8",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_96 = ["fill"]
const _hoisted_97 = { class: "input-desc" }
const _hoisted_98 = { class: "input-container-wrapper" }
const _hoisted_99 = { class: "input-container-02" }
const _hoisted_100 = ["onUpdate:modelValue", "onInput"]
const _hoisted_101 = {
  for: "",
  class: "label"
}
const _hoisted_102 = ["onClick"]
const _hoisted_103 = ["onClick"]
const _hoisted_104 = {
  key: 6,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_105 = { class: "width-max-520" }
const _hoisted_106 = { class: "card-title" }
const _hoisted_107 = { class: "fs-16 mt-12" }
const _hoisted_108 = ["innerHTML"]
const _hoisted_109 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_110 = ["onUpdate:modelValue", "onInput"]
const _hoisted_111 = { class: "text-container" }
const _hoisted_112 = { class: "text" }
const _hoisted_113 = {
  key: 7,
  class: "x-card-question-padding x-box-card",
  style: {"min-height":"420px"}
}
const _hoisted_114 = { class: "input-group-container" }
const _hoisted_115 = { class: "fs-14 opa-08" }
const _hoisted_116 = { class: "input-inner" }
const _hoisted_117 = { class: "input-title" }
const _hoisted_118 = {
  width: "8",
  height: "8",
  viewBox: "0 0 8 8",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_119 = ["fill"]
const _hoisted_120 = { class: "input-desc" }
const _hoisted_121 = { class: "input-container-wrapper" }
const _hoisted_122 = { class: "input-container-02" }
const _hoisted_123 = ["onUpdate:modelValue", "onInput"]
const _hoisted_124 = {
  for: "",
  class: "label"
}
const _hoisted_125 = ["onClick"]
const _hoisted_126 = ["onClick"]
const _hoisted_127 = {
  key: 8,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_128 = { class: "width-max-520" }
const _hoisted_129 = { class: "card-title" }
const _hoisted_130 = { class: "fs-16 mt-12" }
const _hoisted_131 = ["innerHTML"]
const _hoisted_132 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_133 = ["onUpdate:modelValue", "onInput"]
const _hoisted_134 = { class: "text-container" }
const _hoisted_135 = { class: "text" }
const _hoisted_136 = {
  key: 9,
  class: "x-card-question-padding x-box-card",
  style: {"min-height":"420px"}
}
const _hoisted_137 = { class: "input-group-container" }
const _hoisted_138 = { class: "fs-14 opa-08" }
const _hoisted_139 = { class: "input-inner" }
const _hoisted_140 = { class: "input-title" }
const _hoisted_141 = {
  width: "8",
  height: "8",
  viewBox: "0 0 8 8",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_142 = ["fill"]
const _hoisted_143 = { class: "input-desc" }
const _hoisted_144 = { class: "input-container-wrapper" }
const _hoisted_145 = { class: "input-container-02" }
const _hoisted_146 = ["onUpdate:modelValue", "onInput"]
const _hoisted_147 = {
  for: "",
  class: "label"
}
const _hoisted_148 = ["onClick"]
const _hoisted_149 = ["onClick"]
const _hoisted_150 = {
  key: 10,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_151 = { class: "width-max-520" }
const _hoisted_152 = { class: "card-title" }
const _hoisted_153 = { class: "fs-16 mt-12" }
const _hoisted_154 = ["innerHTML"]
const _hoisted_155 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_156 = ["onUpdate:modelValue", "onInput"]
const _hoisted_157 = { class: "text-container" }
const _hoisted_158 = { class: "text" }
const _hoisted_159 = {
  key: 11,
  class: "x-card-question-padding x-box-card",
  style: {"min-height":"420px"}
}
const _hoisted_160 = { class: "input-group-container" }
const _hoisted_161 = { class: "fs-14 opa-08" }
const _hoisted_162 = { class: "input-inner" }
const _hoisted_163 = { class: "input-title" }
const _hoisted_164 = {
  width: "8",
  height: "8",
  viewBox: "0 0 8 8",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_165 = ["fill"]
const _hoisted_166 = { class: "input-desc" }
const _hoisted_167 = { class: "input-container-wrapper" }
const _hoisted_168 = { class: "input-container-02" }
const _hoisted_169 = ["onUpdate:modelValue", "onInput"]
const _hoisted_170 = {
  for: "",
  class: "label"
}
const _hoisted_171 = ["onClick"]
const _hoisted_172 = ["onClick"]
const _hoisted_173 = {
  key: 3,
  class: "body-width modal"
}
const _hoisted_174 = { class: "box-card" }
const _hoisted_175 = { class: "modal-title" }
const _hoisted_176 = { class: "modal-body" }
const _hoisted_177 = { class: "modal-footer" }
const _hoisted_178 = {
  key: 4,
  class: "menu-container flex-center fixed"
}
const _hoisted_179 = { class: "body-width flex-center" }
const _hoisted_180 = {
  class: "mr-auto",
  style: {"width":"18rem"}
}
const _hoisted_181 = ["src"]
const _hoisted_182 = {
  key: 1,
  src: _imports_3,
  alt: "Happily logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_183 = {
  key: 0,
  class: "ml-auto flex-center"
}
const _hoisted_184 = {
  key: 0,
  src: _imports_4,
  alt: "navigate_left_black_24px",
  class: "icon-24 mr-16"
}
const _hoisted_185 = {
  key: 1,
  src: _imports_5,
  alt: "white_navigate_back_24px",
  class: "icon-24 mr-16"
}
const _hoisted_186 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_187 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-24 ml-16"
}
const _hoisted_188 = {
  key: 5,
  class: "mock-menu-container"
}
const _hoisted_189 = {
  key: 6,
  class: "body-width",
  style: {"padding-top":"6rem"}
}
const _hoisted_190 = {
  key: 0,
  class: "box-card mb-32 center bg-FFFFFF",
  style: {"min-height":"420px","padding":"8rem 3.2rem 3.2rem"}
}
const _hoisted_191 = { class: "max-width-520 mx-auto" }
const _hoisted_192 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_193 = { class: "mt-12 fs-16" }
const _hoisted_194 = { class: "mt-32 fs-16" }
const _hoisted_195 = {
  key: 0,
  src: _imports_0,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_196 = {
  key: 1,
  src: _imports_1,
  alt: "icons_white_arrow_right_24px",
  class: "icon-16 ml-16"
}
const _hoisted_197 = {
  key: 1,
  class: "btn btn-create btn-progress btn-50 btn-primary mx-auto",
  style: {"width":"16.6rem","margin-top":"6.4rem"}
}
const _hoisted_198 = {
  key: 1,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_199 = {
  key: 0,
  class: "width-max-520"
}
const _hoisted_200 = { class: "card-title" }
const _hoisted_201 = { class: "fs-16 mt-12" }
const _hoisted_202 = ["innerHTML"]
const _hoisted_203 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_204 = ["onUpdate:modelValue", "onInput"]
const _hoisted_205 = { class: "text-container" }
const _hoisted_206 = { class: "text" }
const _hoisted_207 = {
  key: 2,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_208 = { class: "width-max-520" }
const _hoisted_209 = { class: "card-title" }
const _hoisted_210 = { class: "fs-16 mt-12" }
const _hoisted_211 = ["innerHTML"]
const _hoisted_212 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_213 = ["onUpdate:modelValue", "onInput"]
const _hoisted_214 = { class: "text-container" }
const _hoisted_215 = { class: "text" }
const _hoisted_216 = {
  key: 3,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_217 = { class: "width-max-520" }
const _hoisted_218 = { class: "card-title" }
const _hoisted_219 = { class: "fs-16 mt-12" }
const _hoisted_220 = ["innerHTML"]
const _hoisted_221 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_222 = ["onUpdate:modelValue", "onInput"]
const _hoisted_223 = { class: "text-container" }
const _hoisted_224 = { class: "text" }
const _hoisted_225 = {
  key: 4,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_226 = { class: "width-max-520" }
const _hoisted_227 = { class: "card-title" }
const _hoisted_228 = { class: "fs-16 mt-12" }
const _hoisted_229 = ["innerHTML"]
const _hoisted_230 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_231 = ["onUpdate:modelValue", "onInput"]
const _hoisted_232 = { class: "text-container" }
const _hoisted_233 = { class: "text" }
const _hoisted_234 = {
  key: 5,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_235 = { class: "width-max-520" }
const _hoisted_236 = { class: "card-title" }
const _hoisted_237 = { class: "fs-16 mt-12" }
const _hoisted_238 = ["innerHTML"]
const _hoisted_239 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_240 = ["onUpdate:modelValue", "onInput"]
const _hoisted_241 = { class: "text-container" }
const _hoisted_242 = { class: "text" }
const _hoisted_243 = {
  key: 6,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_244 = { class: "width-max-520" }
const _hoisted_245 = { class: "card-title" }
const _hoisted_246 = { class: "fs-16 mt-12" }
const _hoisted_247 = ["innerHTML"]
const _hoisted_248 = { class: "fs-16 mt-34 fc-CC308A" }
const _hoisted_249 = ["onUpdate:modelValue", "onInput"]
const _hoisted_250 = { class: "text-container" }
const _hoisted_251 = { class: "text" }
const _hoisted_252 = {
  key: 7,
  class: "card-question-padding box-card mb-32 bg-FFFFFF",
  style: {"min-height":"420px"}
}
const _hoisted_253 = { class: "max-width-520 mx-auto center" }
const _hoisted_254 = { class: "fs-24 fc-1B1C1E fw-900" }
const _hoisted_255 = { class: "mt-12 fs-16" }
const _hoisted_256 = { class: "fw-700" }
const _hoisted_257 = { class: "mt-40 fs-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_Doughnut = _resolveComponent("Doughnut")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.step == 'get_start')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("setup.cultureProfile.Setup_your_Culture_Profile")), 1),
            _createElementVNode("div", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.$t("setup.cultureProfile.The_Happily_Culture_Profile")) + " ", 1),
              _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
              _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("setup.cultureProfile.Identify_the_culture_type")), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                false
                  ? (_openBlock(), _createBlock(_component_el_select, {
                      key: 0,
                      disabled: !_ctx.existsTemplates.length,
                      modelValue: _ctx.selectedExistsTemplate,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedExistsTemplate) = $event)),
                      class: "ep-select-44",
                      style: {"width":"42rem","margin-top":"6.4rem"},
                      placeholder: _ctx.$t('setup._Use_the_same_setup')
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item._id,
                            label: item.form_name,
                            value: item._id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["disabled", "modelValue", "placeholder"]))
                  : _createCommentVNode("", true),
                (!_ctx.getStartedButtonLoading)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getStarted())),
                      class: "btn btn-primary"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      (_ctx.isLightColor)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                        : (_openBlock(), _createElementBlock("img", _hoisted_7))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      _cache[17] || (_cache[17] = _createElementVNode("svg", {
                        version: "1.1",
                        id: "L9",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 100 100",
                        "enable-background": "new 0 0 0 0",
                        "xml:space": "preserve",
                        class: "icon-24"
                      }, [
                        _createElementVNode("path", {
                          fill: "#fff",
                          d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        }, [
                          _createElementVNode("animateTransform", {
                            attributeName: "transform",
                            attributeType: "XML",
                            type: "rotate",
                            dur: "1s",
                            from: "0 50 50",
                            to: "360 50 50",
                            repeatCount: "indefinite"
                          })
                        ])
                      ], -1))
                    ])),
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args))),
                  class: "btn btn-default"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "btn-close-modal",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args)))
          }, _cache[18] || (_cache[18] = [
            _createElementVNode("svg", {
              width: "14",
              height: "14",
              viewBox: "0 0 14 14",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z",
                fill: "black"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.step != 'get_start' && _ctx.step != 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.backToLastPage && _ctx.backToLastPage(...args))),
                  class: "btn-left"
                }, _cache[19] || (_cache[19] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "icons_navigate_right_black_24px",
                    class: "icon-16 animetion",
                    style: {"transform":"rotate(180deg)"}
                  }, null, -1)
                ])),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("setup.cultureProfile.Setup_your_Culture_Profile")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                (!_ctx.isFirstStep)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.gotoPreviousStep && _ctx.gotoPreviousStep(...args))),
                      class: _normalizeClass(["ml-16 btn02 btn-40 btn-previous", `${_ctx.canPreviousStep ? '' : 'disable-true'}`])
                    }, [
                      _cache[20] || (_cache[20] = _createElementVNode("img", {
                        src: _imports_0,
                        class: "icon-20 mr-12",
                        alt: "navigate_right_black_24px",
                        style: {"transform":"rotate(180deg)"}
                      }, null, -1)),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("create.Previous")), 1)
                    ], 2))
                  : _createCommentVNode("", true),
                (!_ctx.isLastStep)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.gotoNextStep && _ctx.gotoNextStep(...args))),
                      class: _normalizeClass(["ml-16 btn02 btn-40 btn-next", `${
              _ctx.canNextStep && _ctx.publishSubmitStatus
                ? 'btn-primary'
                : 'disable-true'
            }`])
                    }, [
                      _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("create.Next")), 1),
                      (_ctx.publishSubmitStatus)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_ctx.isLightColor)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_18))
                              : (_openBlock(), _createElementBlock("img", _hoisted_19))
                          ], 64))
                        : (_openBlock(), _createElementBlock("svg", _hoisted_20, _cache[21] || (_cache[21] = [
                            _createElementVNode("path", {
                              fill: "#aaa",
                              d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                            }, [
                              _createElementVNode("animateTransform", {
                                attributeName: "transform",
                                attributeType: "XML",
                                type: "rotate",
                                dur: "1s",
                                from: "0 50 50",
                                to: "360 50 50",
                                repeatCount: "indefinite"
                              })
                            ], -1)
                          ])))
                    ], 2))
                  : _createCommentVNode("", true),
                (_ctx.isLastStep && _ctx.publishSubmitStatus)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.publishSubmit && _ctx.publishSubmit(...args))),
                      class: "ml-16 btn02 btn-40 btn-primary"
                    }, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("create.Publish")), 1),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("img", {
                          src: _imports_2,
                          class: "icon-20",
                          style: _normalizeStyle(`${_ctx.isLightColor ? 'filter: invert(1)' : ''}`),
                          alt: "icons_white_publish_24px"
                        }, null, 4)
                      ])
                    ]))
                  : (_ctx.isLastStep)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("create.Publish")), 1),
                        _cache[22] || (_cache[22] = _createElementVNode("svg", {
                          width: "3rem",
                          height: "3rem",
                          version: "1.1",
                          id: "L9",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          x: "0px",
                          y: "0px",
                          viewBox: "0 0 100 100",
                          "enable-background": "new 0 0 0 0",
                          "xml:space": "preserve"
                        }, [
                          _createElementVNode("path", {
                            fill: "#aaa",
                            d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          }, [
                            _createElementVNode("animateTransform", {
                              attributeName: "transform",
                              attributeType: "XML",
                              type: "rotate",
                              dur: "1s",
                              from: "0 50 50",
                              to: "360 50 50",
                              repeatCount: "indefinite"
                            })
                          ])
                        ], -1))
                      ]))
                    : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step != 'get_start' && _ctx.step != 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (item, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `menu_${i}`,
                    class: _normalizeClass(`menu ${_ctx.tabNextStepCls(item.step)}`),
                    style: _normalizeStyle(`display: ${item.visible === false ? 'none' : ''}`),
                    onClick: ($event: any) => (_ctx.gotoStep(item.step))
                  }, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, _toDisplayString(item.name), 1),
                      _createElementVNode("div", _hoisted_32, _toDisplayString(item.title), 1),
                      (item.desc)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(item.desc), 1))
                        : _createCommentVNode("", true)
                    ])
                  ], 14, _hoisted_29))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_34, [
                (_ctx.step == 'OAIC_archetypes_1_6_old')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                      (_ctx.loaded && _ctx.oaicArchetypes)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                            _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.lang(
                    _ctx.oaicArchetypes.scenarios.dominantCharacteristics,
                    "title"
                  )) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.dominantCharacteristics.order) + "/6) ", 1),
                            _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                            _createElementVNode("div", {
                              class: "fs-20 fw-700 mt-40",
                              innerHTML: 
                  _ctx.lang(
                    _ctx.oaicArchetypes.scenarios.dominantCharacteristics,
                    'admin_description'
                  )
                
                            }, null, 8, _hoisted_39),
                            _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.OAIC_dominant_characteristicsMax > -1
                    ? _ctx.OAIC_dominant_characteristicsMax
                    : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios
                  .dominantCharacteristics.questions, (question) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: question.key,
                                class: "fill-number-form"
                              }, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "number",
                                  pattern: "\\d*",
                                  class: _normalizeClass(["input-points", {
                      validate: !_ctx.validateGroupValue('dominantCharacteristics'),
                    }]),
                                  "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                  onInput: ($event: any) => (
                      _ctx.onChangeWeight(
                        $event,
                        'dominantCharacteristics',
                        question
                      )
                    )
                                }, null, 42, _hoisted_41), [
                                  [_vModelText, question.value]
                                ]),
                                _createElementVNode("div", _hoisted_42, [
                                  _createElementVNode("div", {
                                    class: "bg-points",
                                    style: _normalizeStyle(`width: ${question.value}%;`)
                                  }, null, 4),
                                  _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                                ])
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'OAIC_archetypes_1_6')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                      (_ctx.oaicArchetypes.scenarios)
                        ? (_openBlock(), _createBlock(_component_Doughnut, {
                            key: 0,
                            id: "OAIC_archetypes_1_6",
                            q1: 
                _ctx.oaicArchetypes.scenarios.dominantCharacteristics.questions[0]
                  .value
              ,
                            q2: 
                _ctx.oaicArchetypes.scenarios.dominantCharacteristics.questions[3]
                  .value
              ,
                            q3: 
                _ctx.oaicArchetypes.scenarios.dominantCharacteristics.questions[1]
                  .value
              ,
                            q4: 
                _ctx.oaicArchetypes.scenarios.dominantCharacteristics.questions[2]
                  .value
              
                          }, null, 8, ["q1", "q2", "q3", "q4"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_45, [
                        _createElementVNode("div", null, [
                          _createElementVNode("h1", null, _toDisplayString(_ctx.lang(
                      _ctx.oaicArchetypes.scenarios.dominantCharacteristics,
                      "title"
                    )), 1),
                          _createElementVNode("h3", _hoisted_46, _toDisplayString(_ctx.$t(
                      "question.cultureProfile.Which_best_desribes_your_ideal_organization"
                    )), 1)
                        ]),
                        _createElementVNode("div", _hoisted_47, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios
                    .dominantCharacteristics.questions, (question, order) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: question.key,
                              class: "input-container"
                            }, [
                              _createElementVNode("div", _hoisted_48, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_49, [
                                  _createElementVNode("rect", {
                                    width: "8",
                                    height: "8",
                                    rx: "4",
                                    fill: _ctx.getOptionColorByValue(order)
                                  }, null, 8, _hoisted_50)
                                ])),
                                _createTextVNode(" " + _toDisplayString(_ctx.lang(question, "hint")), 1)
                              ]),
                              _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.lang(question, "admin_title")), 1),
                              _createElementVNode("div", _hoisted_52, [
                                _createElementVNode("div", _hoisted_53, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "number",
                                    pattern: "\\d*",
                                    class: _normalizeClass(["input", {
                          validate: !_ctx.validateGroupValue(
                            'dominantCharacteristics'
                          ),
                        }]),
                                    "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                    onInput: ($event: any) => (
                          _ctx.onChangeWeight(
                            $event,
                            'dominantCharacteristics',
                            question
                          )
                        )
                                  }, null, 42, _hoisted_54), [
                                    [_vModelText, question.value]
                                  ]),
                                  _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.$t("setup.cultureProfile.Rate")), 1)
                                ]),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-minus", {
                        disabled: question.value <= 0,
                      }]),
                                  onClick: ($event: any) => (
                        _ctx.decreaseValue('dominantCharacteristics', question)
                      )
                                }, _cache[23] || (_cache[23] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                                ]), 10, _hoisted_56),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-plus", {
                        disabled: question.value >= 100,
                      }]),
                                  onClick: ($event: any) => (
                        _ctx.increaseValue('dominantCharacteristics', question)
                      )
                                }, _cache[24] || (_cache[24] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                                ]), 10, _hoisted_57)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'OAIC_archetypes_2_6_old')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                      _createElementVNode("div", _hoisted_59, [
                        _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.lang(
                    _ctx.oaicArchetypes.scenarios.organizationalLeadership,
                    "title"
                  )) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.organizationalLeadership.order) + "/6) ", 1),
                        _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                        _createElementVNode("div", {
                          class: "fs-20 fw-700 mt-40",
                          innerHTML: 
                  _ctx.lang(
                    _ctx.oaicArchetypes.scenarios.organizationalLeadership,
                    'admin_description'
                  )
                
                        }, null, 8, _hoisted_62),
                        _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.OAIC_organizational_leadershipMax > -1
                    ? _ctx.OAIC_organizational_leadershipMax
                    : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios
                  .organizationalLeadership.questions, (question) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: question.key,
                            class: "fill-number-form"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input-points", {
                      validate: !_ctx.validateGroupValue('organizationalLeadership'),
                    }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                      _ctx.onChangeWeight(
                        $event,
                        'organizationalLeadership',
                        question
                      )
                    )
                            }, null, 42, _hoisted_64), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("div", _hoisted_65, [
                              _createElementVNode("div", {
                                class: "bg-points",
                                style: _normalizeStyle(`width: ${question.value}%;`)
                              }, null, 4),
                              _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'OAIC_archetypes_2_6')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                      (_ctx.oaicArchetypes.scenarios)
                        ? (_openBlock(), _createBlock(_component_Doughnut, {
                            key: 0,
                            id: "OAIC_archetypes_2_6",
                            q1: 
                _ctx.oaicArchetypes.scenarios.organizationalLeadership.questions[0]
                  .value
              ,
                            q2: 
                _ctx.oaicArchetypes.scenarios.organizationalLeadership.questions[3]
                  .value
              ,
                            q3: 
                _ctx.oaicArchetypes.scenarios.organizationalLeadership.questions[1]
                  .value
              ,
                            q4: 
                _ctx.oaicArchetypes.scenarios.organizationalLeadership.questions[2]
                  .value
              
                          }, null, 8, ["q1", "q2", "q3", "q4"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_68, [
                        _createElementVNode("div", null, [
                          _createElementVNode("h1", null, _toDisplayString(_ctx.lang(
                      _ctx.oaicArchetypes.scenarios.organizationalLeadership,
                      "title"
                    )), 1),
                          _createElementVNode("h3", _hoisted_69, _toDisplayString(_ctx.$t(
                      "question.cultureProfile.Which_best_desribes_your_ideal_leadership"
                    )), 1)
                        ]),
                        _createElementVNode("div", _hoisted_70, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios
                    .organizationalLeadership.questions, (question, order) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: question.key,
                              class: "input-container"
                            }, [
                              _createElementVNode("div", _hoisted_71, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_72, [
                                  _createElementVNode("rect", {
                                    width: "8",
                                    height: "8",
                                    rx: "4",
                                    fill: _ctx.getOptionColorByValue(order)
                                  }, null, 8, _hoisted_73)
                                ])),
                                _createTextVNode(" " + _toDisplayString(_ctx.lang(question, "hint")), 1)
                              ]),
                              _createElementVNode("div", _hoisted_74, _toDisplayString(_ctx.lang(question, "admin_title")), 1),
                              _createElementVNode("div", _hoisted_75, [
                                _createElementVNode("div", _hoisted_76, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "number",
                                    pattern: "\\d*",
                                    class: _normalizeClass(["input", {
                          validate: !_ctx.validateGroupValue(
                            'organizationalLeadership'
                          ),
                        }]),
                                    "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                    onInput: ($event: any) => (
                          _ctx.onChangeWeight(
                            $event,
                            'organizationalLeadership',
                            question
                          )
                        )
                                  }, null, 42, _hoisted_77), [
                                    [_vModelText, question.value]
                                  ]),
                                  _createElementVNode("label", _hoisted_78, _toDisplayString(_ctx.$t("setup.cultureProfile.Rate")), 1)
                                ]),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-minus", {
                        disabled: question.value <= 0,
                      }]),
                                  onClick: ($event: any) => (
                        _ctx.decreaseValue('organizationalLeadership', question)
                      )
                                }, _cache[25] || (_cache[25] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                                ]), 10, _hoisted_79),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-plus", {
                        disabled: question.value >= 100,
                      }]),
                                  onClick: ($event: any) => (
                        _ctx.increaseValue('organizationalLeadership', question)
                      )
                                }, _cache[26] || (_cache[26] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                                ]), 10, _hoisted_80)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'OAIC_archetypes_3_6_old')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                      _createElementVNode("div", _hoisted_82, [
                        _createElementVNode("div", _hoisted_83, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.managementOfEmployees, "title")) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.managementOfEmployees.order) + "/6) ", 1),
                        _createElementVNode("div", _hoisted_84, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                        _createElementVNode("div", {
                          class: "fs-20 fw-700 mt-40",
                          innerHTML: 
                  _ctx.lang(
                    _ctx.oaicArchetypes.scenarios.managementOfEmployees,
                    'admin_description'
                  )
                
                        }, null, 8, _hoisted_85),
                        _createElementVNode("div", _hoisted_86, _toDisplayString(_ctx.OAIC_management_of_employeesMax > -1
                    ? _ctx.OAIC_management_of_employeesMax
                    : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios
                  .managementOfEmployees.questions, (question) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: question.key,
                            class: "fill-number-form"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input-points", {
                      validate: !_ctx.validateGroupValue('managementOfEmployees'),
                    }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                      _ctx.onChangeWeight($event, 'managementOfEmployees', question)
                    )
                            }, null, 42, _hoisted_87), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("div", _hoisted_88, [
                              _createElementVNode("div", {
                                class: "bg-points",
                                style: _normalizeStyle(`width: ${question.value}%;`)
                              }, null, 4),
                              _createElementVNode("div", _hoisted_89, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'OAIC_archetypes_3_6')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_90, [
                      (_ctx.oaicArchetypes.scenarios)
                        ? (_openBlock(), _createBlock(_component_Doughnut, {
                            key: 0,
                            id: "OAIC_archetypes_3_6",
                            q1: 
                _ctx.oaicArchetypes.scenarios.managementOfEmployees.questions[0]
                  .value
              ,
                            q2: 
                _ctx.oaicArchetypes.scenarios.managementOfEmployees.questions[3]
                  .value
              ,
                            q3: 
                _ctx.oaicArchetypes.scenarios.managementOfEmployees.questions[1]
                  .value
              ,
                            q4: 
                _ctx.oaicArchetypes.scenarios.managementOfEmployees.questions[2]
                  .value
              
                          }, null, 8, ["q1", "q2", "q3", "q4"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_91, [
                        _createElementVNode("div", null, [
                          _createElementVNode("h1", null, _toDisplayString(_ctx.lang(
                      _ctx.oaicArchetypes.scenarios.managementOfEmployees,
                      "title"
                    )), 1),
                          _createElementVNode("h3", _hoisted_92, _toDisplayString(_ctx.$t(
                      "question.cultureProfile.Which_best_desribes_the_ideal_role_managers_play"
                    )), 1)
                        ]),
                        _createElementVNode("div", _hoisted_93, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios
                    .managementOfEmployees.questions, (question, order) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: question.key,
                              class: "input-container"
                            }, [
                              _createElementVNode("div", _hoisted_94, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_95, [
                                  _createElementVNode("rect", {
                                    width: "8",
                                    height: "8",
                                    rx: "4",
                                    fill: _ctx.getOptionColorByValue(order)
                                  }, null, 8, _hoisted_96)
                                ])),
                                _createTextVNode(" " + _toDisplayString(_ctx.lang(question, "hint")), 1)
                              ]),
                              _createElementVNode("div", _hoisted_97, _toDisplayString(_ctx.lang(question, "admin_title")), 1),
                              _createElementVNode("div", _hoisted_98, [
                                _createElementVNode("div", _hoisted_99, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "number",
                                    pattern: "\\d*",
                                    class: _normalizeClass(["input", {
                          validate: !_ctx.validateGroupValue(
                            'managementOfEmployees'
                          ),
                        }]),
                                    "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                    onInput: ($event: any) => (
                          _ctx.onChangeWeight(
                            $event,
                            'managementOfEmployees',
                            question
                          )
                        )
                                  }, null, 42, _hoisted_100), [
                                    [_vModelText, question.value]
                                  ]),
                                  _createElementVNode("label", _hoisted_101, _toDisplayString(_ctx.$t("setup.cultureProfile.Rate")), 1)
                                ]),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-minus", {
                        disabled: question.value <= 0,
                      }]),
                                  onClick: ($event: any) => (_ctx.decreaseValue('managementOfEmployees', question))
                                }, _cache[27] || (_cache[27] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                                ]), 10, _hoisted_102),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-plus", {
                        disabled: question.value >= 100,
                      }]),
                                  onClick: ($event: any) => (_ctx.increaseValue('managementOfEmployees', question))
                                }, _cache[28] || (_cache[28] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                                ]), 10, _hoisted_103)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'OAIC_archetypes_4_6_old')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_104, [
                      _createElementVNode("div", _hoisted_105, [
                        _createElementVNode("div", _hoisted_106, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.organizationGlue, "title")) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.organizationGlue.order) + "/6) ", 1),
                        _createElementVNode("div", _hoisted_107, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                        _createElementVNode("div", {
                          class: "fs-20 fw-700 mt-40",
                          innerHTML: 
                  _ctx.lang(
                    _ctx.oaicArchetypes.scenarios.organizationGlue,
                    'admin_description'
                  )
                
                        }, null, 8, _hoisted_108),
                        _createElementVNode("div", _hoisted_109, _toDisplayString(_ctx.OAIC_organization_glueMax > -1 ? _ctx.OAIC_organization_glueMax : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.organizationGlue
                  .questions, (question) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: question.key,
                            class: "fill-number-form"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input-points", {
                      validate: !_ctx.validateGroupValue('organizationGlue'),
                    }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                      _ctx.onChangeWeight($event, 'organizationGlue', question)
                    )
                            }, null, 42, _hoisted_110), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("div", _hoisted_111, [
                              _createElementVNode("div", {
                                class: "bg-points",
                                style: _normalizeStyle(`width: ${question.value}%;`)
                              }, null, 4),
                              _createElementVNode("div", _hoisted_112, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'OAIC_archetypes_4_6')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_113, [
                      (_ctx.oaicArchetypes.scenarios)
                        ? (_openBlock(), _createBlock(_component_Doughnut, {
                            key: 0,
                            id: "OAIC_archetypes_4_6",
                            q1: _ctx.oaicArchetypes.scenarios.organizationGlue.questions[0].value,
                            q2: _ctx.oaicArchetypes.scenarios.organizationGlue.questions[3].value,
                            q3: _ctx.oaicArchetypes.scenarios.organizationGlue.questions[1].value,
                            q4: _ctx.oaicArchetypes.scenarios.organizationGlue.questions[2].value
                          }, null, 8, ["q1", "q2", "q3", "q4"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_114, [
                        _createElementVNode("div", null, [
                          _createElementVNode("h1", null, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.organizationGlue, "title")), 1),
                          _createElementVNode("h3", _hoisted_115, _toDisplayString(_ctx.$t(
                      "question.cultureProfile.Which_best_desribes_what_ideally_holds_the_organization_together"
                    )), 1)
                        ]),
                        _createElementVNode("div", _hoisted_116, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios
                    .organizationGlue.questions, (question, order) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: question.key,
                              class: "input-container"
                            }, [
                              _createElementVNode("div", _hoisted_117, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_118, [
                                  _createElementVNode("rect", {
                                    width: "8",
                                    height: "8",
                                    rx: "4",
                                    fill: _ctx.getOptionColorByValue(order)
                                  }, null, 8, _hoisted_119)
                                ])),
                                _createTextVNode(" " + _toDisplayString(_ctx.lang(question, "hint")), 1)
                              ]),
                              _createElementVNode("div", _hoisted_120, _toDisplayString(_ctx.lang(question, "admin_title")), 1),
                              _createElementVNode("div", _hoisted_121, [
                                _createElementVNode("div", _hoisted_122, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "number",
                                    pattern: "\\d*",
                                    class: _normalizeClass(["input", {
                          validate: !_ctx.validateGroupValue('organizationGlue'),
                        }]),
                                    "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                    onInput: ($event: any) => (
                          _ctx.onChangeWeight($event, 'organizationGlue', question)
                        )
                                  }, null, 42, _hoisted_123), [
                                    [_vModelText, question.value]
                                  ]),
                                  _createElementVNode("label", _hoisted_124, _toDisplayString(_ctx.$t("setup.cultureProfile.Rate")), 1)
                                ]),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-minus", {
                        disabled: question.value <= 0,
                      }]),
                                  onClick: ($event: any) => (_ctx.decreaseValue('organizationGlue', question))
                                }, _cache[29] || (_cache[29] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                                ]), 10, _hoisted_125),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-plus", {
                        disabled: question.value >= 100,
                      }]),
                                  onClick: ($event: any) => (_ctx.increaseValue('organizationGlue', question))
                                }, _cache[30] || (_cache[30] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                                ]), 10, _hoisted_126)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'OAIC_archetypes_5_6_old')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_127, [
                      _createElementVNode("div", _hoisted_128, [
                        _createElementVNode("div", _hoisted_129, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.strategicEmphases, "title")) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.strategicEmphases.order) + "/6) ", 1),
                        _createElementVNode("div", _hoisted_130, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                        _createElementVNode("div", {
                          class: "fs-20 fw-700 mt-40",
                          innerHTML: 
                  _ctx.lang(
                    _ctx.oaicArchetypes.scenarios.strategicEmphases,
                    'admin_description'
                  )
                
                        }, null, 8, _hoisted_131),
                        _createElementVNode("div", _hoisted_132, _toDisplayString(_ctx.OAIC_strategic_emphasesMax > -1
                    ? _ctx.OAIC_strategic_emphasesMax
                    : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.strategicEmphases
                  .questions, (question) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: question.key,
                            class: "fill-number-form"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input-points", {
                      validate: !_ctx.validateGroupValue('strategicEmphases'),
                    }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                      _ctx.onChangeWeight($event, 'strategicEmphases', question)
                    )
                            }, null, 42, _hoisted_133), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("div", _hoisted_134, [
                              _createElementVNode("div", {
                                class: "bg-points",
                                style: _normalizeStyle(`width: ${question.value}%;`)
                              }, null, 4),
                              _createElementVNode("div", _hoisted_135, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.step == 'OAIC_archetypes_5_6')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_136, [
                      (_ctx.oaicArchetypes.scenarios)
                        ? (_openBlock(), _createBlock(_component_Doughnut, {
                            key: 0,
                            id: "OAIC_archetypes_5_6",
                            q1: 
                _ctx.oaicArchetypes.scenarios.strategicEmphases.questions[0].value
              ,
                            q2: 
                _ctx.oaicArchetypes.scenarios.strategicEmphases.questions[3].value
              ,
                            q3: 
                _ctx.oaicArchetypes.scenarios.strategicEmphases.questions[1].value
              ,
                            q4: 
                _ctx.oaicArchetypes.scenarios.strategicEmphases.questions[2].value
              
                          }, null, 8, ["q1", "q2", "q3", "q4"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_137, [
                        _createElementVNode("div", null, [
                          _createElementVNode("h1", null, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.strategicEmphases, "title")), 1),
                          _createElementVNode("h3", _hoisted_138, _toDisplayString(_ctx.$t(
                      "question.cultureProfile.Which_best_desribes_the_basis_of_how_decisions_should_be_made"
                    )), 1)
                        ]),
                        _createElementVNode("div", _hoisted_139, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios
                    .strategicEmphases.questions, (question, order) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: question.key,
                              class: "input-container"
                            }, [
                              _createElementVNode("div", _hoisted_140, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_141, [
                                  _createElementVNode("rect", {
                                    width: "8",
                                    height: "8",
                                    rx: "4",
                                    fill: _ctx.getOptionColorByValue(order)
                                  }, null, 8, _hoisted_142)
                                ])),
                                _createTextVNode(" " + _toDisplayString(_ctx.lang(question, "hint")), 1)
                              ]),
                              _createElementVNode("div", _hoisted_143, _toDisplayString(_ctx.lang(question, "admin_title")), 1),
                              _createElementVNode("div", _hoisted_144, [
                                _createElementVNode("div", _hoisted_145, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "number",
                                    pattern: "\\d*",
                                    class: _normalizeClass(["input", {
                          validate: !_ctx.validateGroupValue('strategicEmphases'),
                        }]),
                                    "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                    onInput: ($event: any) => (
                          _ctx.onChangeWeight($event, 'strategicEmphases', question)
                        )
                                  }, null, 42, _hoisted_146), [
                                    [_vModelText, question.value]
                                  ]),
                                  _createElementVNode("label", _hoisted_147, _toDisplayString(_ctx.$t("setup.cultureProfile.Rate")), 1)
                                ]),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-minus", {
                        disabled: question.value <= 0,
                      }]),
                                  onClick: ($event: any) => (_ctx.decreaseValue('strategicEmphases', question))
                                }, _cache[31] || (_cache[31] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                                ]), 10, _hoisted_148),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-plus", {
                        disabled: question.value >= 100,
                      }]),
                                  onClick: ($event: any) => (_ctx.increaseValue('strategicEmphases', question))
                                }, _cache[32] || (_cache[32] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                                ]), 10, _hoisted_149)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : (_ctx.step == 'OAIC_archetypes_6_6_old')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_150, [
                        _createElementVNode("div", _hoisted_151, [
                          _createElementVNode("div", _hoisted_152, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.criteriaOfSuccess, "title")) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.criteriaOfSuccess.order) + "/6) ", 1),
                          _createElementVNode("div", _hoisted_153, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                          _createElementVNode("div", {
                            class: "fs-20 fw-700 mt-40",
                            innerHTML: 
                  _ctx.lang(
                    _ctx.oaicArchetypes.scenarios.criteriaOfSuccess,
                    'admin_description'
                  )
                
                          }, null, 8, _hoisted_154),
                          _createElementVNode("div", _hoisted_155, _toDisplayString(_ctx.OAIC_criteria_of_successMax > -1
                    ? _ctx.OAIC_criteria_of_successMax
                    : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.criteriaOfSuccess
                  .questions, (question) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: question.key,
                              class: "fill-number-form"
                            }, [
                              _withDirectives(_createElementVNode("input", {
                                type: "number",
                                pattern: "\\d*",
                                class: _normalizeClass(["input-points", {
                      validate: !_ctx.validateGroupValue('criteriaOfSuccess'),
                    }]),
                                "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                onInput: ($event: any) => (
                      _ctx.onChangeWeight($event, 'criteriaOfSuccess', question)
                    )
                              }, null, 42, _hoisted_156), [
                                [_vModelText, question.value]
                              ]),
                              _createElementVNode("div", _hoisted_157, [
                                _createElementVNode("div", {
                                  class: "bg-points",
                                  style: _normalizeStyle(`width: ${question.value}%;`)
                                }, null, 4),
                                _createElementVNode("div", _hoisted_158, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                              ])
                            ]))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                (_ctx.step == 'OAIC_archetypes_6_6')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_159, [
                      (_ctx.oaicArchetypes.scenarios)
                        ? (_openBlock(), _createBlock(_component_Doughnut, {
                            key: 0,
                            id: "OAIC_archetypes_6_6",
                            q1: 
                _ctx.oaicArchetypes.scenarios.criteriaOfSuccess.questions[0].value
              ,
                            q2: 
                _ctx.oaicArchetypes.scenarios.criteriaOfSuccess.questions[3].value
              ,
                            q3: 
                _ctx.oaicArchetypes.scenarios.criteriaOfSuccess.questions[1].value
              ,
                            q4: 
                _ctx.oaicArchetypes.scenarios.criteriaOfSuccess.questions[2].value
              
                          }, null, 8, ["q1", "q2", "q3", "q4"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_160, [
                        _createElementVNode("div", null, [
                          _createElementVNode("h1", null, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.criteriaOfSuccess, "title")), 1),
                          _createElementVNode("h3", _hoisted_161, _toDisplayString(_ctx.$t(
                      "question.cultureProfile.Which_best_desribes_what_success_looks_like"
                    )), 1)
                        ]),
                        _createElementVNode("div", _hoisted_162, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios
                    .criteriaOfSuccess.questions, (question, order) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: question.key,
                              class: "input-container"
                            }, [
                              _createElementVNode("div", _hoisted_163, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_164, [
                                  _createElementVNode("rect", {
                                    width: "8",
                                    height: "8",
                                    rx: "4",
                                    fill: _ctx.getOptionColorByValue(order)
                                  }, null, 8, _hoisted_165)
                                ])),
                                _createTextVNode(" " + _toDisplayString(_ctx.lang(question, "hint")), 1)
                              ]),
                              _createElementVNode("div", _hoisted_166, _toDisplayString(_ctx.lang(question, "admin_title")), 1),
                              _createElementVNode("div", _hoisted_167, [
                                _createElementVNode("div", _hoisted_168, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "number",
                                    pattern: "\\d*",
                                    class: _normalizeClass(["input", {
                          validate: !_ctx.validateGroupValue('criteriaOfSuccess'),
                        }]),
                                    "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                    onInput: ($event: any) => (
                          _ctx.onChangeWeight($event, 'criteriaOfSuccess', question)
                        )
                                  }, null, 42, _hoisted_169), [
                                    [_vModelText, question.value]
                                  ]),
                                  _createElementVNode("label", _hoisted_170, _toDisplayString(_ctx.$t("setup.cultureProfile.Rate")), 1)
                                ]),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-minus", {
                        disabled: question.value <= 0,
                      }]),
                                  onClick: ($event: any) => (_ctx.decreaseValue('criteriaOfSuccess', question))
                                }, _cache[33] || (_cache[33] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "-", -1)
                                ]), 10, _hoisted_171),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["mc-button mc-button-plus", {
                        disabled: question.value >= 100,
                      }]),
                                  onClick: ($event: any) => (_ctx.increaseValue('criteriaOfSuccess', question))
                                }, _cache[34] || (_cache[34] = [
                                  _createElementVNode("div", { class: "mc-button-inner" }, "+", -1)
                                ]), 10, _hoisted_172)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[35] || (_cache[35] = _createElementVNode("div", null, null, -1))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step == 'setup_complete')
      ? (_openBlock(), _createElementBlock("div", _hoisted_173, [
          _createElementVNode("div", _hoisted_174, [
            _createElementVNode("div", _hoisted_175, _toDisplayString(_ctx.$t("setup.cultureProfile.Setup_Completed")), 1),
            _createElementVNode("div", _hoisted_176, _toDisplayString(_ctx.$t("setup.cultureProfile.Thank_you_for_setting_up")), 1),
            _createElementVNode("div", _hoisted_177, [
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args))),
                  class: "btn btn-primary"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close_Window")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "btn-close-modal",
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.closeTab && _ctx.closeTab(...args)))
          }, _cache[36] || (_cache[36] = [
            _createElementVNode("svg", {
              width: "14",
              height: "14",
              viewBox: "0 0 14 14",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z",
                fill: "black"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_178, [
          _createElementVNode("div", _hoisted_179, [
            _createElementVNode("div", _hoisted_180, [
              (_ctx.companyLogoUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.companyLogoUrl,
                    alt: "Happily logo",
                    class: "company-logo-image",
                    style: {"width":"18rem"}
                  }, null, 8, _hoisted_181))
                : (_openBlock(), _createElementBlock("img", _hoisted_182)),
              _createTextVNode(" " + _toDisplayString(_ctx.step), 1)
            ]),
            (
          _ctx.step == 'OAIC_archetypes_1_6' ||
          _ctx.step == 'OAIC_archetypes_2_6' ||
          _ctx.step == 'OAIC_archetypes_3_6' ||
          _ctx.step == 'OAIC_archetypes_4_6' ||
          _ctx.step == 'OAIC_archetypes_5_6' ||
          _ctx.step == 'OAIC_archetypes_6_6'
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_183, [
                  (!(_ctx.step == 'OAIC_archetypes_1_6'))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.backBTN_OAIC_dominant())),
                        class: "btn btn-50 btn-primary"
                      }, [
                        (_ctx.isLightColor)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_184))
                          : (_openBlock(), _createElementBlock("img", _hoisted_185)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Previous")), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.nextBTN_OAIC_dominant())),
                    class: _normalizeClass(["btn btn-50 btn-primary ml-16", { 'disable-true': _ctx.nextBTN_OAIC_dominantValidate }])
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Next")), 1),
                    (_ctx.isLightColor && !_ctx.nextBTN_OAIC_dominantValidate)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_186))
                      : (_openBlock(), _createElementBlock("img", _hoisted_187))
                  ], 2)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_188))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_189, [
          (_ctx.step == 'get_start')
            ? (_openBlock(), _createElementBlock("div", _hoisted_190, [
                _createElementVNode("div", _hoisted_191, [
                  _createElementVNode("div", _hoisted_192, _toDisplayString(_ctx.$t("setup.cultureProfile.Setup_your_Culture_Profile")), 1),
                  _createElementVNode("div", _hoisted_193, _toDisplayString(_ctx.$t("setup.cultureProfile.The_Happily_Culture_Profile")), 1),
                  _createElementVNode("div", _hoisted_194, _toDisplayString(_ctx.$t("setup.cultureProfile.Identify_the_culture_type")), 1)
                ]),
                _createVNode(_component_el_select, {
                  disabled: !_ctx.existsTemplates.length,
                  modelValue: _ctx.selectedExistsTemplate,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedExistsTemplate) = $event)),
                  class: "ep-select-44",
                  style: {"width":"42rem","margin-top":"6.4rem"},
                  placeholder: _ctx.$t('setup._Use_the_same_setup')
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existsTemplates, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item._id,
                        label: item.form_name,
                        value: item._id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["disabled", "modelValue", "placeholder"]),
                (!_ctx.getStartedButtonLoading)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.getStarted())),
                      class: "mx-auto btn btn-50 btn-primary",
                      style: {"width":"16.6rem","margin-top":"6.4rem"}
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      (_ctx.isLightColor)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_195))
                        : (_openBlock(), _createElementBlock("img", _hoisted_196))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_197, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t("btn.Get_Started")), 1),
                      _cache[37] || (_cache[37] = _createElementVNode("svg", {
                        version: "1.1",
                        id: "L9",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 100 100",
                        "enable-background": "new 0 0 0 0",
                        "xml:space": "preserve"
                      }, [
                        _createElementVNode("path", {
                          fill: "#fff",
                          d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        }, [
                          _createElementVNode("animateTransform", {
                            attributeName: "transform",
                            attributeType: "XML",
                            type: "rotate",
                            dur: "1s",
                            from: "0 50 50",
                            to: "360 50 50",
                            repeatCount: "indefinite"
                          })
                        ])
                      ], -1))
                    ]))
              ]))
            : (_ctx.step == 'OAIC_archetypes_1_6')
              ? (_openBlock(), _createElementBlock("div", _hoisted_198, [
                  (_ctx.loaded && _ctx.oaicArchetypes)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_199, [
                        _createElementVNode("div", _hoisted_200, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.dominantCharacteristics, "title")) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.dominantCharacteristics.order) + "/6) ", 1),
                        _createElementVNode("div", _hoisted_201, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                        _createElementVNode("div", {
                          class: "fs-20 fw-700 mt-40",
                          innerHTML: 
            _ctx.lang(
              _ctx.oaicArchetypes.scenarios.dominantCharacteristics,
              'admin_description'
            )
          
                        }, null, 8, _hoisted_202),
                        _createElementVNode("div", _hoisted_203, _toDisplayString(_ctx.OAIC_dominant_characteristicsMax > -1
              ? _ctx.OAIC_dominant_characteristicsMax
              : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.dominantCharacteristics
            .questions, (question) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: question.key,
                            class: "fill-number-form"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input-points", {
                validate: !_ctx.validateGroupValue('dominantCharacteristics'),
              }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                _ctx.onChangeWeight($event, 'dominantCharacteristics', question)
              )
                            }, null, 42, _hoisted_204), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("div", _hoisted_205, [
                              _createElementVNode("div", {
                                class: "bg-points",
                                style: _normalizeStyle(`width: ${question.value}%;`)
                              }, null, 4),
                              _createElementVNode("div", _hoisted_206, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                            ])
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_ctx.step == 'OAIC_archetypes_2_6')
                ? (_openBlock(), _createElementBlock("div", _hoisted_207, [
                    _createElementVNode("div", _hoisted_208, [
                      _createElementVNode("div", _hoisted_209, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.organizationalLeadership, "title")) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.organizationalLeadership.order) + "/6) ", 1),
                      _createElementVNode("div", _hoisted_210, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                      _createElementVNode("div", {
                        class: "fs-20 fw-700 mt-40",
                        innerHTML: 
            _ctx.lang(
              _ctx.oaicArchetypes.scenarios.organizationalLeadership,
              'admin_description'
            )
          
                      }, null, 8, _hoisted_211),
                      _createElementVNode("div", _hoisted_212, _toDisplayString(_ctx.OAIC_organizational_leadershipMax > -1
              ? _ctx.OAIC_organizational_leadershipMax
              : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.organizationalLeadership
            .questions, (question) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: question.key,
                          class: "fill-number-form"
                        }, [
                          _withDirectives(_createElementVNode("input", {
                            type: "number",
                            pattern: "\\d*",
                            class: _normalizeClass(["input-points", {
                validate: !_ctx.validateGroupValue('organizationalLeadership'),
              }]),
                            "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                            onInput: ($event: any) => (
                _ctx.onChangeWeight($event, 'organizationalLeadership', question)
              )
                          }, null, 42, _hoisted_213), [
                            [_vModelText, question.value]
                          ]),
                          _createElementVNode("div", _hoisted_214, [
                            _createElementVNode("div", {
                              class: "bg-points",
                              style: _normalizeStyle(`width: ${question.value}%;`)
                            }, null, 4),
                            _createElementVNode("div", _hoisted_215, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                          ])
                        ]))
                      }), 128))
                    ])
                  ]))
                : (_ctx.step == 'OAIC_archetypes_3_6')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_216, [
                      _createElementVNode("div", _hoisted_217, [
                        _createElementVNode("div", _hoisted_218, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.managementOfEmployees, "title")) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.managementOfEmployees.order) + "/6) ", 1),
                        _createElementVNode("div", _hoisted_219, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                        _createElementVNode("div", {
                          class: "fs-20 fw-700 mt-40",
                          innerHTML: 
            _ctx.lang(
              _ctx.oaicArchetypes.scenarios.managementOfEmployees,
              'admin_description'
            )
          
                        }, null, 8, _hoisted_220),
                        _createElementVNode("div", _hoisted_221, _toDisplayString(_ctx.OAIC_management_of_employeesMax > -1
              ? _ctx.OAIC_management_of_employeesMax
              : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.managementOfEmployees
            .questions, (question) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: question.key,
                            class: "fill-number-form"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              pattern: "\\d*",
                              class: _normalizeClass(["input-points", {
                validate: !_ctx.validateGroupValue('managementOfEmployees'),
              }]),
                              "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                              onInput: ($event: any) => (
                _ctx.onChangeWeight($event, 'managementOfEmployees', question)
              )
                            }, null, 42, _hoisted_222), [
                              [_vModelText, question.value]
                            ]),
                            _createElementVNode("div", _hoisted_223, [
                              _createElementVNode("div", {
                                class: "bg-points",
                                style: _normalizeStyle(`width: ${question.value}%;`)
                              }, null, 4),
                              _createElementVNode("div", _hoisted_224, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]))
                  : (_ctx.step == 'OAIC_archetypes_4_6')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_225, [
                        _createElementVNode("div", _hoisted_226, [
                          _createElementVNode("div", _hoisted_227, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.organizationGlue, "title")) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.organizationGlue.order) + "/6) ", 1),
                          _createElementVNode("div", _hoisted_228, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                          _createElementVNode("div", {
                            class: "fs-20 fw-700 mt-40",
                            innerHTML: 
            _ctx.lang(_ctx.oaicArchetypes.scenarios.organizationGlue, 'admin_description')
          
                          }, null, 8, _hoisted_229),
                          _createElementVNode("div", _hoisted_230, _toDisplayString(_ctx.OAIC_organization_glueMax > -1 ? _ctx.OAIC_organization_glueMax : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.organizationGlue
            .questions, (question) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: question.key,
                              class: "fill-number-form"
                            }, [
                              _withDirectives(_createElementVNode("input", {
                                type: "number",
                                pattern: "\\d*",
                                class: _normalizeClass(["input-points", {
                validate: !_ctx.validateGroupValue('organizationGlue'),
              }]),
                                "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                onInput: ($event: any) => (_ctx.onChangeWeight($event, 'organizationGlue', question))
                              }, null, 42, _hoisted_231), [
                                [_vModelText, question.value]
                              ]),
                              _createElementVNode("div", _hoisted_232, [
                                _createElementVNode("div", {
                                  class: "bg-points",
                                  style: _normalizeStyle(`width: ${question.value}%;`)
                                }, null, 4),
                                _createElementVNode("div", _hoisted_233, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                              ])
                            ]))
                          }), 128))
                        ])
                      ]))
                    : (_ctx.step == 'OAIC_archetypes_5_6')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_234, [
                          _createElementVNode("div", _hoisted_235, [
                            _createElementVNode("div", _hoisted_236, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.strategicEmphases, "title")) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.strategicEmphases.order) + "/6) ", 1),
                            _createElementVNode("div", _hoisted_237, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                            _createElementVNode("div", {
                              class: "fs-20 fw-700 mt-40",
                              innerHTML: 
            _ctx.lang(
              _ctx.oaicArchetypes.scenarios.strategicEmphases,
              'admin_description'
            )
          
                            }, null, 8, _hoisted_238),
                            _createElementVNode("div", _hoisted_239, _toDisplayString(_ctx.OAIC_strategic_emphasesMax > -1 ? _ctx.OAIC_strategic_emphasesMax : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.strategicEmphases
            .questions, (question) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: question.key,
                                class: "fill-number-form"
                              }, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "number",
                                  pattern: "\\d*",
                                  class: _normalizeClass(["input-points", {
                validate: !_ctx.validateGroupValue('strategicEmphases'),
              }]),
                                  "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                  onInput: ($event: any) => (_ctx.onChangeWeight($event, 'strategicEmphases', question))
                                }, null, 42, _hoisted_240), [
                                  [_vModelText, question.value]
                                ]),
                                _createElementVNode("div", _hoisted_241, [
                                  _createElementVNode("div", {
                                    class: "bg-points",
                                    style: _normalizeStyle(`width: ${question.value}%;`)
                                  }, null, 4),
                                  _createElementVNode("div", _hoisted_242, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                                ])
                              ]))
                            }), 128))
                          ])
                        ]))
                      : (_ctx.step == 'OAIC_archetypes_6_6')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_243, [
                            _createElementVNode("div", _hoisted_244, [
                              _createElementVNode("div", _hoisted_245, _toDisplayString(_ctx.lang(_ctx.oaicArchetypes.scenarios.criteriaOfSuccess, "title")) + " (" + _toDisplayString(_ctx.oaicArchetypes.scenarios.criteriaOfSuccess.order) + "/6) ", 1),
                              _createElementVNode("div", _hoisted_246, _toDisplayString(_ctx.$t("question.cultureProfile.Assessing_each_aspect_divide")), 1),
                              _createElementVNode("div", {
                                class: "fs-20 fw-700 mt-40",
                                innerHTML: 
            _ctx.lang(
              _ctx.oaicArchetypes.scenarios.criteriaOfSuccess,
              'admin_description'
            )
          
                              }, null, 8, _hoisted_247),
                              _createElementVNode("div", _hoisted_248, _toDisplayString(_ctx.OAIC_criteria_of_successMax > -1 ? _ctx.OAIC_criteria_of_successMax : 0) + " " + _toDisplayString(_ctx.$t("assessmentTest.POINTS_REMAINING")), 1),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oaicArchetypes.scenarios.criteriaOfSuccess
            .questions, (question) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: question.key,
                                  class: "fill-number-form"
                                }, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "number",
                                    pattern: "\\d*",
                                    class: _normalizeClass(["input-points", {
                validate: !_ctx.validateGroupValue('criteriaOfSuccess'),
              }]),
                                    "onUpdate:modelValue": ($event: any) => ((question.value) = $event),
                                    onInput: ($event: any) => (_ctx.onChangeWeight($event, 'criteriaOfSuccess', question))
                                  }, null, 42, _hoisted_249), [
                                    [_vModelText, question.value]
                                  ]),
                                  _createElementVNode("div", _hoisted_250, [
                                    _createElementVNode("div", {
                                      class: "bg-points",
                                      style: _normalizeStyle(`width: ${question.value}%;`)
                                    }, null, 4),
                                    _createElementVNode("div", _hoisted_251, _toDisplayString(_ctx.lang(question, "admin_title")), 1)
                                  ])
                                ]))
                              }), 128))
                            ])
                          ]))
                        : (_ctx.step == 'setup_complete')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_252, [
                              _createElementVNode("div", _hoisted_253, [
                                _createElementVNode("div", _hoisted_254, _toDisplayString(_ctx.$t("setup.Setup_Complete")), 1),
                                _createElementVNode("div", _hoisted_255, [
                                  _createTextVNode(_toDisplayString(_ctx.$t("setup.Thank_you_for_setting")) + "  ", 1),
                                  _createElementVNode("span", _hoisted_256, _toDisplayString(_ctx.$t("main.Culture_Profile")), 1),
                                  _createTextVNode("  " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                                ]),
                                _createElementVNode("div", _hoisted_257, _toDisplayString(_ctx.$t("setup.You_can_now_close_this_window")), 1),
                                _createElementVNode("div", {
                                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.closeTab())),
                                  class: "btn btn-50 btn-primary mx-auto mt-40",
                                  style: {"width":"9rem"}
                                }, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}